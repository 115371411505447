import React from 'react'
import { variants } from './variants'
import { motion } from "framer-motion"
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    svg: {
        fill: "#beddda",
    }
})
const TopWave = ( ) => {
    const classes = useStyles();
    return (
        <svg width="100%" viewBox="0 0 1365 130.92" className={classes.svg}>
            <motion.path
                transition={{ flip: Infinity, duration: 25 }}
                variants={variants}
                animate="animate"
            />
        </svg >
    )
}

export default TopWave
