import React from "react"
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "@material-ui/core";
import Navbar from "./components/Navbar/Navbar";
import Footer from './components/Footer/Footer';
import "./layout.css";
import theme from '../../theme';
import Hero from '../Hero/Hero'
import heroImg from '../../assets/images/hero.jpeg'

const Layout = ({ children, hero }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Navbar siteTitle={data.site.siteMetadata.title} />
          {children}
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
