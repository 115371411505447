import { Link } from "gatsby"
import React from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  Hidden,
  IconButton,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener
} from "@material-ui/core"
import { ExpandMore, Menu as MenuIcon } from "@material-ui/icons"
import logo from "../../../../assets/images/webfluent-horizontal.png"
import { navItems } from "../../../../assets/utils/services"

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})(props => (
  <MenuList
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#beddda",
    top: 0
  },
  navContainer: {
    padding: 10,
  },
  navItem: {
    textAlign: "center",
    alignItems: "bottom",
  },
  link: {
    color: "#000000",
    textDecoration: "none",
  },
  logo: {
    margin: 0,
    width: "14rem",
  },
  gridNavItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const NavBar = () => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const anchorRef = React.useRef(null);

  const handleCloseMenu = () => {
    setAnchorEl2(null)
  };

  const handleClick = event => {
    setAnchorEl2(event.currentTarget)
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar>
        <Grid container spacing={2} className={classes.navContainer}>
          <Grid item xs={8} md={4}>
            <Link to="/">
              <img
                src={logo}
                alt={"Webfluent Web Design & Development"}
                className={classes.logo}
              />
            </Link>
          </Grid>

          <Hidden smDown>
            {navItems.map(item =>
              item.subItems ? (
                <Grid
                  key={item.name}
                  item
                  md
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handleToggle}
                  onMouseLeave={handleClose}
                  className={classes.gridNavItem}
                >
                  <div className={classes.navItem}>
                    <Link to={item.link} className={classes.link}>
                      <Typography variant="h5">
                        {item.name}
                        <ExpandMore fontSize="default" />
                      </Typography>
                    </Link>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <StyledMenu autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {item.subItems.map(item => (
                                  <Link
                                    key={item.name}
                                    to={item.link}
                                    className={classes.link}
                                  >
                                    <StyledMenuItem>
                                      <ListItemText primary={item.name} />
                                    </StyledMenuItem>
                                  </Link>
                                ))}
                              </StyledMenu>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </Grid>
              ) : (
                  <Grid key={item.name} item md className={classes.gridNavItem}>
                    <div className={classes.navItem}>
                      <Link to={item.link} className={classes.link}>
                        <Typography variant="h5">{item.name}</Typography>
                      </Link>
                    </div>
                  </Grid>
                )
            )}
          </Hidden>

          <Hidden mdUp>
            <Grid item xs={4}>
              <IconButton
                aria-controls="responsive-menu"
                style={{ float: "right", padding: 5 }}
                onClick={handleClick}
              >
                <MenuIcon
                  style={{ color: "white" }}
                  aria-controls="responsive-menu"
                />
              </IconButton>
            </Grid>

            <Menu
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={handleCloseMenu}
              id="responsive-menu"
            >
              {navItems.map((item, i) => (
                <MenuItem key={i * -1}>
                  <Link to={item.link} className={classes.link}>
                    <Typography variant="h5">{item.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
