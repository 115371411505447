import React from "react"
import { Grid, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { navItems } from "../../../../assets/utils/services"
import { Link } from "gatsby"
import clsx from "clsx"
import Logo from "../../../../assets/images/logo-horizontal.png"
import { Facebook, LinkedIn } from "@material-ui/icons"
import TopWave from "../../../WaveDiv/components/TopWave"
import { useLocation } from "@reach/router"
const useStyles = makeStyles(theme => ({
  gridItems: {
    display: "flex",
    justifyContent: "center",
    "& a": {
      fontSize: "25px",
    },
    alignItems: "center",
    flexDirection: "column",
  },
  text: {
    color: "#565b5e",
    fontFamily: "Roboto",
    textDecoration: "none",
    [theme.breakpoints.only("xs")]: {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
  title: {
    fontSize: "18px",
  },
  footer: {
    padding: `${theme.spacing(3)}px 0`,
    backgroundColor: "#fff",
    overflowY: "hidden",
  },
  footerWithWave: {
    [theme.breakpoints.up("md")]: {
      marginTop: "-113px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "-60px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "-45px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-20px",
    },
    backgroundColor: "#beddda",
  },
  iconSquare: {
    width: "2em",
    height: "2em",
    borderRadius: "5px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(37,137,124)",
    padding: theme.spacing(1),
    "& a": {
      color: "white",
      display: "contents",
    },
  },
  link: {
    textDecoration: "none",
    color: "#36abf9",
    opacity: ".75",
  },
  img: {
    [theme.breakpoints.up("md")]: {
      width: "60%",
      margin: '0 auto'
    },
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      margin: 0
    },
  },
  iconGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)}px 0`,
    },
    alignItems: "center",
  },
  mainGrid: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  }
}))

const Footer = ({ wave, white }) => {
  const classes = useStyles()
  const location = useLocation()

  return (
    <>
      {location.pathname !== "/" && (
        <div
          style={{
            paddingTop: "25px",
            overflow: "hidden",
            display: 'flex'
          }}
        >
          <TopWave />
        </div>
      )}
      <footer
        className={clsx({
          [classes.footer]: true,
          [classes.footerWithWave]: location.pathname !== "/",
        })}
      >
        <Grid container align='center' justify='center'>
          <Hidden smDown>
            <Grid className={classes.gridItems} item xs={12} md={3}>
              <img className={classes.img} src={Logo} alt="WebFluent Logo" />
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item className={clsx(classes.mainGrid)} xs={12} md={6}>
              <Grid
                container
                alignItems="center"
                style={{ height: "100%" }}
                justify="center"
              >
                {navItems.map((i, ind) => (
                  <Grid
                    key={ind}
                    className={classes.gridItems}
                    item
                    xs={4}
                    md={2}
                  >
                    <Link key={ind * -1} className={classes.text} to={i.link}>
                      {i.name}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Hidden>
          <Grid className={clsx(classes.iconGrid)} xs={12} item md={3}>
            <div className={classes.iconSquare}>
              <a
                href="https://www.facebook.com/WebFluent-103188898167054"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
            </div>
            <Hidden mdUp>
              <img className={classes.img} src={Logo} alt="WebFluent Logo" />
            </Hidden>
            <div className={classes.iconSquare}>
              <a
                href="https://www.linkedin.com/company/webfluent"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedIn />
              </a>
            </div>
          </Grid>
        </Grid>
      </footer>
    </>
  )
}

export default Footer
