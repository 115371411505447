export const navItems = [
  {
    name: "About",
    link: '/about'
  },
  {
    name: "Services",
    link: '/services',
    subItems: [
      {
        name: "Web Development",
        link: '/services/web-development',
      },
      {
        name: "Redesign & Rebuild",
        link: '/services/redesign-rebuild',
      },
      {
        name: "SEO",
        link: '/services/seo',
      },
      {
        name: "Maintenance & Management",
        link: '/services/maintenance-management',
      },
      {
        name: "Web Hosting",
        link: '/services/web-hosting',
      },
    ]
  },
  {
    name: "Our Work",
    link: '/portfolio'
  },
  {
    name: "Contact",
    link: '/contact'
  },
  {
    name: "Blog",
    link: '/blog'
  },
];