import React from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-background-image"
import { styled } from "@material-ui/core/styles"
const useStyles = makeStyles({
  heroImg: {
    marginBottom: 40,
    height: "80vh",
  },
})

const Hero = ({ text }) => {
  const classes = useStyles()
  //   const { image } = useStaticQuery(graphql`
  //     )
  //   return (
  //     <Img
  //       fluid={image.childImageSharp.fixed}
  //       objectFit="cover"
  //       objectPosition="center"
  //     />
  //   )
  // }
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "hero.jpeg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <Img Tag="section" className={classes.heroImg} fluid={imageData} />
        )
      }}
    />
  )
}

export default Hero
